/* eslint-disable */
import LazyLoad from "vanilla-lazyload";
import inView from "in-view";
import Granim from "granim";

const HTML = document.querySelector("html");

class App {
  constructor() {
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      // eslint-disable-next-line camelcase
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == undefined) {
          const figure = $el.closest("figure");
          if (figure) figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure) figure.classList.add("img-is-loaded");
      },
      // eslint-disable-next-line camelcase
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      },
    });

    this.gradient();
    this.inViewport();
  }

  gradient() {
    const granimInstance = new Granim({
      element: ".gradient",
      name: "granim",
      opacity: [1, 1],
      direction: "diagonal",
      states: {
        "default-state": {
          gradients: [
            ["#DDBDDD", "#8387F4"],
            ["#F0D9BE", "#E94370"],
            // ["#98EFF9", "#F2E48A"],
          ],
        },
      },
    });
  }

  gradient2() {
    const canvas = document.querySelector(".gradient");
    const context = canvas.getContext("2d");
    let time = 0;

    const color = function (x, y, r, g, b) {
      context.fillStyle = `rgb(${r}, ${g}, ${b})`;
      context.fillRect(x, y, 10, 10);
    };
    const R = function (x, y, time) {
      return Math.floor(192 + 64 * Math.cos((x * x - y * y) / 300 + time));
    };

    const G = function (x, y, time) {
      return Math.floor(
        192 +
          64 *
            Math.sin(
              (x * x * Math.cos(time / 4) + y * y * Math.sin(time / 3)) / 300
            )
      );
    };

    const B = function (x, y, time) {
      return Math.floor(
        192 +
          64 *
            Math.sin(
              5 * Math.sin(time / 9) +
                ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100
            )
      );
    };

    const startAnimation = function () {
      for (let x = 0; x <= 30; x++) {
        for (let y = 0; y <= 30; y++) {
          color(x, y, R(x, y, time), G(x, y, time), B(x, y, time));
        }
      }
      time += 0.03;
      window.requestAnimationFrame(startAnimation);
    };

    startAnimation();
  }

  inViewport() {
    // In viewport
    inView.offset({
      bottom: window.innerHeight * 0,
    });
    inView(".js-reveal:not(.is-visible)")
      .on("enter", function (elt) {
        elt.dispatchEvent(new Event("inview"));
        elt.classList.add("is-visible");
        elt.classList.remove("not-visible");

        const video = elt.querySelector("video");

        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay == "true") ||
          (video && video.dataset.autoplay == "true")
        ) {
          const v = video || elt;
          const playPromise = v.play();
          if (playPromise !== null) {
            playPromise.catch(() => {
              v.play();
            });
          }
        }
      })
      .on("exit", function (elt) {
        const video = elt.querySelector("video");
        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay != "") ||
          (video && video.dataset.autoplay != "")
        ) {
          const v = video || elt;
          v.pause();
        }

        elt.dispatchEvent(new Event("outview"));
        elt.classList.add("not-visible");
      });
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new App();
});
